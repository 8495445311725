import { Box, Button, CircularProgress, IconButton, Paper } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useEffect, useMemo, useState } from "react";
import MainDialog from "../../Components/MainDialog";
import { TAsset } from "../../Models/App";
import { useStoreActions } from "../../Stores";
import Typo from "Components/Typo";
import { Close } from "@material-ui/icons";

export interface PdfDialogProps {
    pdf: TAsset;
    open: boolean;
    afterClose?: Function;
}

// Check if the user is on an iPhone
const isIphone = () => /iPad|iPhone/.test(navigator.userAgent);

const PdfDialog: FC<PdfDialogProps> = (props) => {
    const { pdf, open } = props;
    const classes = useStyles();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const { closePdfDialog } = useStoreActions(({ Dialogs: { closePdfDialog } }) => ({ closePdfDialog }));
    const onClose = () => {
        props.afterClose?.();
        closePdfDialog();
    };
    const onIframeLoad = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
        const elem = document.getElementById("end");
        // console.log(elem);
    };
    const isPdf = useMemo(() => {
        return pdf.mimetype === "application/pdf";
    }, [pdf]);

    useEffect(() => {
        if (isIphone()) {
            setShowDownload(true);
        }
    }, []);
    return (
        <MainDialog
            open={open}
            mini={true}
            onClose={() => onClose()}
            id="pdf-dialog"
            PaperProps={{
                className: classes.gradientBg,
            }}
            containerClassname={classes.padding}
        >
            <Box className={clsx(classes.root)}>
                <Box className={classes.container}>
                    {showDownload ? <DownloadPdf onHideText={() => setShowDownload(false)} url={pdf.url} /> : null}
                    <iframe className={classes.iframe} title="pdf-dialog" onLoad={(e) => onIframeLoad(e)} src={isPdf ? `${pdf.url.replace("https://", "//")}` : `https://view.officeapps.live.com/op/embed.aspx?src=${pdf.url}`}></iframe>
                </Box>
            </Box>
        </MainDialog>
    );
};
/**
 * unselectable="on"
 * class="cui-toolbar-buttondock alignright
 * id="ChromelessStatusBar.RightDock"
 */
const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            overflow: "auto",
            display: "flex",
        },
        gradientBg: {
            // background: `${getGradientFromTheme(theme)} !important`
        },
        iframe: {
            width: 990,
            minHeight: `calc(100vh - 66px)`,
            border: "1px solid black",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: `calc(100vh - 66px)`,
            },
            // '& .cui-toolbar-buttondock alignright': {
            //     display: 'none'
            // }
        },
        container: {
            width: 1000,
            margin: "auto",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
        padding: {
            padding: 0,
            width: 1000,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
        cover: {
            backgroundColor: "#fff",
            opacity: 0.2,
        },
        downloadRoot: {
            position: "fixed",
            zIndex: theme.zIndex.snackbar + 10,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },

        downloadPaper: {
            width: 300,
            padding: theme.spacing(2),
        },
    });
});

export default PdfDialog;

const DownloadPdf = ({ url, onHideText, name }: { url: string; onHideText: () => void; name?: string }) => {
    const classes = useStyles();
    const [isDownloading, setIsDownloading] = useState(false);
    const downloadPdfFromUrl = async () => {
        setIsDownloading(true);
        const a = document.createElement("a");
        a.id = "download-pdf";
        document.getElementById("pdf-dialog")?.appendChild(a);
        a.style.display = "none";
        a.href = url;
        a.download = name ?? "download.pdf";
        a.click();
        document.getElementById("pdf-dialog")?.removeChild(a);
        setIsDownloading(false);
        onHideText();
    };

    return (
        <Box className={classes.downloadRoot}>
            <Paper elevation={2} className={classes.downloadPaper}>
                <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                    <Box display="flex" alignItems="start" justifyContent="space-between">
                        <Typo variant="body1" weight="regular">
                            Trouble viewing the PDF? Download it to your device.
                        </Typo>
                        <IconButton onClick={onHideText}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box height={12} />
                    <Button fullWidth color="primary" variant="contained" onClick={downloadPdfFromUrl}>
                        {isDownloading ? <CircularProgress /> : "Download"}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};
